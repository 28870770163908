import React from "react"

import styled from "styled-components";

import Content from "../components/content"
import Nav from "../components/nav"
import StyledA from "../components/styleda"
import { PageTitle, Subtitle } from "../components/titles"

const NoMarginP = styled.p`
  margin: 0;
`;

const MyName = styled.span`
  font-weight: 600;
`;

const papers = [
  {
    title: "Synthesizing CRDTs from Sequential Data Types with Verified Lifting",
    authors: <><MyName>Shadaj Laddad</MyName>, Conor Power, Mae Milano, Alvin Cheung, Joseph M. Hellerstein</>,
    conference: <>OOPSLA 2022 (conditionally accepted, <StyledA href="/papers/crdt-synthesis.pdf">preprint</StyledA>)</>
  },
  {
    title: "VizSmith: Automated Visualization Synthesis by Mining Data-Science Notebooks",
    authors: <>Rohan Bavishi, <MyName>Shadaj Laddad</MyName>, Hiroaki Yoshida, Mukul R. Prasad, Koushik Sen</>,
    conference: <>ASE 2021 (<StyledA href="https://ieeexplore.ieee.org/document/9678696">IEEE</StyledA>, <StyledA href="/papers/ase2021-vizsmith.pdf">pdf</StyledA>)</>
  },
  {
    title: "Fluid Quotes: Metaprogramming across Abstraction Boundaries with Dependent Types",
    authors: <><MyName>Shadaj Laddad</MyName> and Koushik Sen</>,
    conference: <>GPCE 2020 (<StyledA href="https://dl.acm.org/doi/10.1145/3425898.3426953">ACM</StyledA>, <StyledA href="/papers/fluid-quotes.pdf">pdf</StyledA>)</>
  },
  {
    title: "ScalaPy: Seamless Python Interoperability for Cross-Platform Scala Programs",
    authors: <><MyName>Shadaj Laddad</MyName> and Koushik Sen</>,
    conference: <>Scala Symposium 2020 (<StyledA href="https://dl.acm.org/doi/abs/10.1145/3426426.3428485">ACM</StyledA>, <StyledA href="/papers/scalapy.pdf">pdf</StyledA>)</>
  }
]

export default class Research extends React.Component {
  render() {
    return (
      <div>
        <Nav current="research"/>
        <Content>
          <PageTitle>I'm always exploring</PageTitle>

          <div style={{
            marginTop: 20,
            marginBottom: 10
          }}>
          I'm a first-year PhD student co-advised by <StyledA href="https://people.eecs.berkeley.edu/~akcheung/">Alvin Cheung</StyledA> and <StyledA href="https://dsf.berkeley.edu/jmh/">Joe Hellerstein</StyledA> at UC Berkeley, where I research programming languages and distributed systems. I'm grateful to be supported by an <StyledA href="https://www.nsfgrfp.org/">NSF Graduate Research Fellowship</StyledA>. As an undergraduate student, I was a member of the Software Productivity Lab advised by <StyledA href="https://people.eecs.berkeley.edu/~ksen">Koushik Sen</StyledA>. I've worked on research exploring new approaches to metaprogramming, systems for seamless language interoperability, automatic parallelization of dynamically-typed programs, and more.
          </div>

          <div>
          My research is driven by the shortcomings in modern programming systems: programming languages that offer a confusing mix of tradeoffs, complex systems that make integrations brittle and inefficient, and tooling that has limited insight into the intent of the developer. In my research, I explore the boundaries of <span style={{ fontWeight: 800 }}>type systems, functional programming, and program synthesis</span> along with attendant innovations that <span style={{ fontWeight: 800 }}>apply these features in systems</span> to make software development more <span style={{ fontWeight: 800 }}>efficient, scalable, and enjoyable</span>.
          </div>

          <Subtitle>Publications</Subtitle>

          {papers.map((paper, i) => {
            return <div style={{
              marginTop: i > 0 ? "15px" : undefined,
            }} key={i}>
              <NoMarginP style={{ fontSize: "22px" }}>{paper["title"]}</NoMarginP>
              <NoMarginP style={{ fontWeight: 300 }}>{paper["authors"]}</NoMarginP>
              <NoMarginP style={{ fontStyle: "italic" }}>{paper["conference"]}</NoMarginP>
            </div>;
          })}

          <Subtitle>Fellowships and Awards</Subtitle>
          <ul style={{
            marginTop: "10px"
          }}>
            <li><StyledA href="https://www.nsfgrfp.org">NSF Graduate Research Fellowship</StyledA> (2021)</li>
            <li><StyledA href="https://www2.eecs.berkeley.edu/Students/Awards/113/">UC Berkeley EECS Major Citation</StyledA> (2021)</li>
            <li><StyledA href="https://neo.com">Neo Scholar</StyledA> (2020)</li>
            <li><StyledA href="https://grad.berkeley.edu/flas">UC Berkeley Foreign Language and Area Studies Fellowship</StyledA> (2019)</li>
            <li><StyledA href="https://hkn.eecs.berkeley.edu">UC Berkeley Eta Kappa Nu</StyledA> (2019)</li>
          </ul>
        </Content>
      </div>
    )
  }
}
