import styled from "styled-components";

export const PageTitle = styled.h2`
  font-family: 'PT Serif', serif;
  font-size: 60px;
  @media (max-width: 800px) {
    font-size: 50px;
    margin-top: 30px;
  }
  font-weight: 700;
  margin-top: 35px;
  margin-bottom: 10px;
`;

export const Subtitle = styled.h2`
  font-family: 'PT Serif', serif;
  font-size: 45px;
  @media (max-width: 800px) {
    font-size: 40px;
  }
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 20px;
`;
