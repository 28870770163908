import styled from "styled-components"

export default styled.div`
  width: calc(100vw - 40px);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  font-family: 'PT Serif', sans-serif;
  font-size: 20px;
  padding-bottom: 40px;
`;
